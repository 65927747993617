import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { isUndefined } from 'lodash'

import Image from '../components/image'
import Promotions from '../components/promotions'
import RelatedEvents from '../components/related-events'
import Modal from '../components/modal'
import Icon from '../components/icon'
import VenueHireRequest from '../components/venue-hire-request'
import { useIsMobile } from '../hooks/screen-size'
import SEO from '../components/seo'

import styles from './rental-rates.module.css'
import Link from '../components/link'

const Toggle = ({ selections, currentSelection, handleSelection }) => {
  if (!Array.isArray(selections) || !selections.length) return ''

  const parsedSelections = selections
    .map(s => {
      if (typeof s === 'string') {
        return {
          value: s,
          selection: s,
        }
      }

      return s
    })
    .slice(0, 2)

  return (
    <div className={`${styles.toggleContainer} flex flex-row w-full`}>
      {parsedSelections.map((s, i) => (
        <div
          key={i}
          className={`${styles.toggleSelection} ${
            s.value === currentSelection ? styles.toggleSelected : ''
          } flex-1`}
          onClick={() => handleSelection(s.value)}
        >
          {s.selection}
        </div>
      ))}
    </div>
  )
}

const CinemaRateTable = ({ locations }) => {
  const [isBef6PM, setIsBef6PM] = useState(true)
  const [isWeekday, setIsWeekday] = useState(true)

  const isMobile = useIsMobile()

  if (isMobile) {
    return (
      <>
        <h2 className={`${styles.sectionTitle} mb-10`}>
          Rental Rates for Cinema Halls &amp; Intermission Bar Foyer
        </h2>
        <div className="mb-4">
          <Toggle
            selections={[
              { selection: 'Before 6PM', value: true },
              { selection: 'After 6PM', value: false },
            ]}
            currentSelection={isBef6PM}
            handleSelection={setIsBef6PM}
          />
        </div>
        <div className="mb-12">
          <Toggle
            selections={[
              { selection: 'Weekday', value: true },
              { selection: 'Weekend', value: false },
            ]}
            currentSelection={isWeekday}
            handleSelection={setIsWeekday}
          />
        </div>
        <div className="w-full mb-8">
          <table className={styles.rateTable}>
            <tbody>
              {locations.map((l, i) => (
                <tr key={i}>
                  <td>
                    <h2>{l.name}</h2>
                  </td>
                  <td>
                    <p>
                      {
                        l.priceList[
                          `${isWeekday ? 'weekday' : 'weekend'}s${
                            isBef6PM ? 'Bef6' : 'Aft6'
                          }`
                        ]
                      }
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.rateTableNote}>
          <p>*Weekdays: Mon-Thu, Weekends: Fri-Sun</p>
          <p>*Price above are based on 4 hour blocks</p>
        </div>
      </>
    )
  }

  return (
    <table className={styles.rateTable}>
      <thead>
        <tr>
          <td rowSpan="2" className={styles.firstColumn}>
            <h2>RENTAL RATE FOR CINEMA HALLS &amp; INTERMISSION BAR FOYER</h2>
          </td>
          <td colSpan="2" className={styles.secondColumn}>
            <h2>BEFORE 6PM</h2>
          </td>
          <td colSpan="2" className={styles.thirdColumn}>
            <h2>AFTER 6PM</h2>
          </td>
        </tr>
        <tr>
          <td>
            <h2>4 HOUR BLOCK ON WEEKDAYS</h2>
            <p>(Mon-Thu)</p>
          </td>
          <td>
            <h2>4 HOUR BLOCK ON WEEKENDS</h2>
            <p>(Fri-Sun)</p>
          </td>
          <td>
            <h2>4 HOUR BLOCK ON WEEKDAYS</h2>
            <p>(Mon-Thu)</p>
          </td>
          <td>
            <h2>4 HOUR BLOCK ON WEEKENDS</h2>
            <p>(Fri-Sun)</p>
          </td>
        </tr>
      </thead>
      <tbody>
        {locations.map((l, i) => (
          <tr key={i}>
            <td>
              <h2>{l.name}</h2>
            </td>
            <td>
              <p>{l.priceList.weekdaysBef6}</p>
            </td>
            <td>
              <p>{l.priceList.weekendsBef6}</p>
            </td>
            <td>
              <p>{l.priceList.weekdaysAft6}</p>
            </td>
            <td>
              <p>{l.priceList.weekendsAft6}</p>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const ParkingRateTable = ({ parking }) => {
  const isMobile = useIsMobile()

  if (!parking.publish) return <></>

  if (isMobile) {
    return (
      <>
        <h2 className={`${styles.sectionTitle} mb-10`}>
          Rental Rates for Rooftop Parking Area
        </h2>
        <table className={styles.rateTable}>
          <tbody>
            <tr>
              <td>
                <h2>STANDARD RATE</h2>
                <h2>(8PM - 4PM)</h2>
              </td>
              <td>
                <p>{parking.rentalFee.standardRate}</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>PEAK RATE</h2>
                <h2>(4PM - 11PM)</h2>
              </td>
              <td>
                <p>{parking.rentalFee.peakRate}</p>
              </td>
            </tr>
            <tr>
              <td>
                <h2>FULL DAY BOOKING</h2>
              </td>
              <td>
                <p>{parking.fullDayBooking}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }

  return (
    <table className={styles.rateTable}>
      <thead>
        <tr>
          <td className={styles.firstColumn}>
            <h2>RENTAL RATE FOR ROOFTOP PARKING AREA</h2>
          </td>
          <td className={styles.secondColumn}>
            <h2>STANDARD RATE</h2>
            <h2>(8PM - 4PM)</h2>
          </td>
          <td className={styles.thirdColumn}>
            <h2>PEAK RATE</h2>
            <h2>(4PM - 11PM)</h2>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <h2>RENTAL FEE</h2>
          </td>
          <td>
            <p>{parking.rentalFee.standardRate}</p>
          </td>
          <td>
            <p>{parking.rentalFee.peakRate}</p>
          </td>
        </tr>
        <tr>
          <td>
            <h2>FULL DAY BOOKING</h2>
          </td>
          <td colSpan="2">
            <p>{parking.fullDayBooking}</p>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const RentalRatesSection = ({ section, amenities }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <div className="w-full">
        <h2 className={`${styles.sectionTitle} mb-8`}>{section.title}</h2>
        <div className="mb-20">
          <div
            className="mb-8 mx-auto w-full md:w-4/5 lg:w-3/5 xl:w-1/2"
            dangerouslySetInnerHTML={{ __html: section.descriptionHtml }}
          />
          <button className="underline" onClick={() => setIsModalOpen(true)}>
            <p>Find out more</p>
          </button>
        </div>
        {/* <div className="mb-12">
          <CinemaRateTable locations={section.cinemaRateTable} />
        </div> */}
        <div>
          <ParkingRateTable parking={section.parkingRateTable} />
        </div>
      </div>
      <Modal
        className={styles.amenitiesModal}
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <>
          <h2 className={`${styles.amenitiesHeader} text-center`}>
            Venue rental rates are inclusive of the following:
          </h2>
          <div className="flex flex-row flex-wrap">
            {amenities.map((a, i) => (
              <div
                key={i}
                className="text-center mx-auto p-6 w-1/2 md:w-1/3 lg:w-1/4"
              >
                {a.image && (
                  <Icon icon={a.image} className={styles.amenityImage} />
                )}
                <p className={styles.amenityLabel}>{a.label}</p>
              </div>
            ))}
          </div>
        </>
      </Modal>
    </>
  )
}

const PackagesSection = ({ section, packages, termsAndConditions }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  packages.forEach(p => {
    p.children = (
      <div className="text-left">
        {p.blurbHtml ? (
          <div
            className={`text-white mb-10 ${styles.packageBlurb}`}
            dangerouslySetInnerHTML={{ __html: p.blurbHtml }}
          />
        ) : (
          ''
        )}
        {p.packageDetails ? (
          <div className={styles.packageDetails}>
            {!isUndefined(p.packageDetails.pricePerPax) ? (
              <div className="flex flex-row justify-between items-center mb-4">
                <h3 className={styles.detailsTitle}>Price/Pax</h3>
                <p className={styles.detailsContent}>
                  {p.packageDetails.pricePerPax}
                </p>
              </div>
            ) : (
              ''
            )}
            {!isUndefined(p.packageDetails.minimumPax) ? (
              <div className="flex flex-row justify-between items-center mb-4">
                <h3 className={styles.detailsTitle}>Minimum Pax</h3>
                <p className={styles.detailsContent}>
                  {p.packageDetails.minimumPax}
                </p>
              </div>
            ) : (
              ''
            )}
            {!isUndefined(p.packageDetails.packageInclusion) &&
            p.packageDetails.packageInclusion.length ? (
              <div className="flex flex-col">
                <h3 className={`${styles.detailsTitle} mb-2`}>
                  Package Includes
                </h3>
                <ul className={styles.detailsList}>
                  {p.packageDetails.packageInclusion.map((include, i) => (
                    <li key={i} className={styles.detailsContent}>
                      {include}
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    )
  })

  return (
    <div className="w-full">
      <h2 className={`${styles.sectionTitle} mb-8`}>{section.title}</h2>
      <div className="mb-12">
        <div
          className="mb-8 mx-auto w-full md:w-4/5 lg:w-3/5 xl:w-1/2"
          dangerouslySetInnerHTML={{ __html: section.descriptionHtml }}
        />
        <p>
          <button
            className="underline cursor"
            onClick={() => setIsModalOpen(true)}
          >
            Terms &amp; Conditions
          </button>{' '}
          apply.
        </p>
      </div>
      <button
        onClick={() => (window.location = '#request')}
        className={`${styles.selectPackageBtn} btn-primary`}
      >
        Select a package
      </button>
      <Promotions
        className={styles.packagePromotions}
        promotions={packages}
        alternateStyling={true}
      />
      <Modal
        className={styles.tncModal}
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
      >
        <div
          className="p-8 text-left"
          dangerouslySetInnerHTML={{ __html: termsAndConditions }}
        ></div>
      </Modal>
    </div>
  )
}

export const RentalRatesTemplate = ({
  page,
  venueNodes,
  rentalPackageNodes,
  location,
}) => {
  const { frontmatter, html: bodyHtml } = page
  const {
    title,
    coverImage,
    contactUs,
    rentalRatesSection,
    packagesSection,
    termsAndConditionsHtml,
    amenitiesInclusion,
  } = frontmatter

  const venues = venueNodes
    ? venueNodes.nodes.map(n => ({ ...n.frontmatter, ...n.fields }))
    : []
  const rentalPackages = rentalPackageNodes
    ? rentalPackageNodes.nodes.map(n => ({
        ...n.frontmatter,
      }))
    : []

  return (
    <>
    <SEO 
      title={"Venue only rental rates | Venue rental package rates"}
      description={"Our venue rental rates also includes the use of certain amenities and equipments and also support for its use. We provide package rates or venue only rates."}
    />
    <div className={`${styles.rentalRatesContainer} py-5 lg:py-20 w-screen`}>
      <div className="container flex flex-col text-center">
        <div className="mb-20">
          <h1 className={`${styles.title} mb-12`}>{title}</h1>
          <Image
            className="mb-12"
            fluid={coverImage && coverImage.childImageSharp.fluid}
          />
          <div
            className="mb-12 mx-auto w-full md:w-4/5 lg:w-3/5 xl:w-1/2"
            dangerouslySetInnerHTML={{ __html: bodyHtml }}
          />
          <div>
            <Link to={contactUs.linkURL} className="btn-primary">
              {contactUs.callToAction}
            </Link>
          </div>
        </div>
        <div className="mb-32">
          <RentalRatesSection
            section={rentalRatesSection}
            amenities={amenitiesInclusion}
          />
        </div>
      </div>
      <div className={`${styles.packageContainer} mb-32`}>
        <div className="container flex flex-col text-center py-16">
          <PackagesSection
            section={packagesSection}
            packages={rentalPackages}
            termsAndConditions={termsAndConditionsHtml}
          />
        </div>
      </div>
      <div className="flex flex-col items-center mb-10">
        <h2 className={`${styles.sectionTitle} mb-10`}>Hire our venue</h2>
        <div className="px-8 w-full md:w-auto">
          <RelatedEvents events={venues} />
        </div>
      </div>
      <VenueHireRequest
        open={location && location.hash === '#request'}
        onClose={() => (window.location = '#')}
      />
    </div>
    </>
  )
}

const RentalRatesPage = ({ data, location }) => {
  return <RentalRatesTemplate location={location} {...data} />
}

RentalRatesPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default RentalRatesPage

export const pageQuery = graphql`
  query RentalRatesPage {
    page: markdownRemark(fields: { slug: { eq: "/rental-rates/" } }) {
      frontmatter {
        title
        coverImage {
          childImageSharp {
            fluid(maxWidth: 1159, maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contactUs {
          callToAction
          linkURL
        }
        amenitiesInclusion {
          label
          image {
            childImageSharp {
              fluid(maxWidth: 90, maxHeight: 55, quality: 90, fit: CONTAIN) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
            name
          }
        }
        rentalRatesSection {
          title
          descriptionHtml
          cinemaRateTable {
            name
            priceList {
              weekdaysBef6
              weekdaysAft6
              weekendsBef6
              weekendsAft6
            }
          }
          parkingRateTable {
            publish
            rentalFee {
              standardRate
              peakRate
            }
            fullDayBooking
          }
        }
        packagesSection {
          title
          descriptionHtml
        }
        termsAndConditionsHtml
      }
      html
    }
    rentalPackageNodes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "rental-packages" } } }
    ) {
      nodes {
        frontmatter {
          title
          blurbHtml
          coverImage {
            childImageSharp {
              fluid(maxWidth: 681, maxHeight: 455, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          packageDetails {
            pricePerPax
            minimumPax
            packageInclusion
          }
        }
      }
    }
    venueNodes: allMarkdownRemark(
      filter: { fields: { collection: { eq: "venues" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          coverImage {
            childImageSharp {
              fluid(maxWidth: 582, maxHeight: 326, quality: 92) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
