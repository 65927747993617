import React from 'react'
import Image from '../components/image'

const Icon = ({ icon, className }) => {
  if (!icon) return ''

  if (!icon.childImageSharp && icon.extension === 'svg') {
    return <img className={className} src={icon.publicURL} alt={icon.name} />
  }

  if (icon.childImageSharp) {
    return (
      <Image
        className={className}
        {...(icon.childImageSharp.fluid
          ? { fluid: icon.childImageSharp.fluid }
          : {})}
        {...(icon.childImageSharp.fixed
          ? { fluid: icon.childImageSharp.fixed }
          : {})}
      />
    )
  }

  return ''
}

export default Icon
